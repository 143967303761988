@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");

* {
  -webkit-overflow-scrolling: touch;
  outline: none !important;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f5f5f5 !important;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.header {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 2em;
}

.h1-header {
  color: black;
  text-align: justify;
  // flex: 1;
  margin-bottom: 2em;
}

.alreadyPaidTitle {
  font-size: 1.25em;
  color: #000 !important;
  font-weight: 100;
}

.container-input-already {
  display: flex;
}

.ui.fluid.input > input {
  width: 100% !important;
  background: #f5f5f5;
}

.ui.fluid.search.selection.dropdown {
  background: #f5f5f5 !important;
}

.cancel {
  position: absolute;
  top: 6px;
  right: 3px;
  cursor: pointer;
}

.btn-form {
  margin-top: 2em;
  display: flex;
  justify-content: center;
}

.info-podcast {
  display: flex;
  flex-direction: column;

  img {
    height: 150px;
    width: 150px;
  }

  p {
    font-size: 1.3em;
  }
}

.switch-toggle {
  display: inline-block;
  background: #171b34;

  input {
    position: absolute;
    opacity: 0;
    background: #1a78c2;
  }

  input + label {
    padding: 4px;
    float: left;
    color: black;
    cursor: pointer;
    border: 1px black solid;
    padding: 0.5em;
    width: 100px;
    margin-bottom: 5px;
  }

  input:checked + label {
    background: #1a78c2;
    color: #f1f1f1;
    border: 1px solid #1a78c2;
  }
}

.offer-list {
  justify-content: center;
}

.subscribe {
  color: var(--title-color);
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;

  padding: 20px;
  text-align: center;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
}

.container {
  color: var(--title-color);
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.player-content {
  overflow: unset !important;
}

.donation {
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: #1a78c2;
  text-align: center;
  margin-top: 1em;
}

.StripeElement {
  clear: both;
  padding: 10px;
  text-align: center;
}

.StripeElementCard {
  clear: both;
  padding: 10px;
  text-align: center;
  border: 1px solid #d6d6d6;
}

.tip-txt {
  text-align: center;
  font-size: 16px;
}

.payment {
  color: black !important;
  flex: 1;
  padding-bottom: 40px;
}

.label-payment {
  border-radius: 6px;
  margin: 0 5px 2.3em 5px;
}

.cgu {
  margin-bottom: 15px;
}

.cgu label {
  margin-top: 10px;
  text-align: center;
  font-size: 80%;
  color: black !important;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;

  a {
    color: #1a78c2;
    text-decoration: underline;
  }
}

.payment-buttons {
  position: relative;
}

.right.icon.header.item {
  color: var(--title-color);
}

i.icon {
  color: #1a78c2;
}
.payment-no-cgu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  cursor: pointer;
}

.footer {
  clear: both;
  padding: 10px;
  color: var(--title-color);
  text-align: right;
  opacity: 0.7;
  height: 45px;
  flex-shrink: 0;
}

.login-image {
  max-width: 100% !important;
}

.login-content {
  padding: 20px;
}

.connexion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.container-player {
  overflow: hidden !important;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  .main {
    display: flex;
    flex-direction: column;
    height: 100%;

    & > div {
      padding: 20px;
      padding-bottom: 0;
      // text-align: center;
      background-color: #171b34;
    }

    & > .header {
      img {
        // max-width: 100%;
        height: 150px !important;
        width: 150px !important;
        z-index: 1000;
        position: relative;
        object-fit: scale-down;
        height: auto;
        width: auto;
        max-height: 80vh;
      }
    }

    & > .main-right {
      h2 {
        color: var(--main-color);
        margin: 0;
        margin-top: 5px;
        margin-bottom: 5px;
      }

      .description-container {
        justify-content: start;
        display: flex;
        color: black;
      }

      p.steps,
      p.steps h5 {
        font-size: 1.5rem;
        font-weight: normal;
      }
    }

    & > .main-right {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    @media only screen and (min-width: 768px) {
      & > .main-right {
        flex: 1;
        display: table;
      }
    }
  }
}
.premium-other {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

#notification-wrapper {
  z-index: 9999999;
}

.cgu-content {
  color: var(--title-color);
  background: var(--background-color);

  padding: 20px;
}

.cgu-monoc {
  color: #000;
  background: #fff;
}

.cgu-monoc .mentions-text {
  color: #000 !important;
}

.ui.cards {
  padding: 2em !important;
}

.mentions-text {
  color: #fff;

  li {
    .title {
      font-size: 1.2em;
    }

    .title,
    .subtitle {
      text-decoration: underline;
    }

    p:first-of-type {
      margin-top: 10px;
    }

    p:last-child {
      margin-bottom: 10px;
    }

    p {
      margin-left: -30px;
    }
  }
}

@media only screen and (min-width: 768px) {
  #root {
    max-width: 420px;
    min-width: 420px;
  }

  body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 768px) {
  .subscribe {
    width: 100%;
  }
}

.subscribe {
  .dimmer {
    background-color: #f5f5f5;
    opacity: 0 !important;
  }

  p {
    color: black;
  }
}

.wrapped {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  flex-wrap: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 2px;
}

.wrapped::-webkit-scrollbar {
  display: none;
}

.payment-buttons a {
  color: var(--title-color) !important;
}

.image-header {
  img {
    max-height: 120px;
  }
}

// MEMBER AREA

.container-premium-area {
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  h3 {
    text-align: center;
    padding-top: 1.25em;
  }
}

.account-user {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.account-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  text-align: center;
}


///////////////// onglet ECOUTE////////////

.subscribe-container {
  h1,
  p,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  div {
    color: black;
  }

  .header {
    color: black !important;
  }
}

.footer-member-area {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 2em;

  a {
    color: black;
    text-decoration: underline;
    font-size: 1.09em;
  }

  @media (max-height: 650px) {
    position: relative;
  }
}

.premium-code-link,
.premium-already-member {
  cursor: pointer;
}

.new-login .dimmer {
  background: none !important;
  background-color: none !important;
}

// PANORAMA component

.panorama-card-container {
  margin: 0.7em auto !important;
  background: white;
  width: 90%;
  display: flex;
  border-radius: 3px;
  box-shadow: 2px 2px 2px grey;
  max-height: 110px;
  border-radius: 4px;
}

.menu-nav {
  text-align: center;
}

.panorama-card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  padding: 1em;
}

.bundle-container {
  padding: 10px;

  .ui.selection.dropdown .menu {
    max-height: 100vh !important;
  }

  .bundle-dropdown {
    z-index: 1001 !important;

    background: var(--background-color) !important;

    .menu {
      background: var(--background-color) !important;

      .item:hover {
        background: #fff !important;
      }
    }

    &>i {
      top: 20px !important;
    }

    .selected-header-item {
      display: flex;
      flex-direction: row;

      .image {
        display: flex;

        align-items: center;
        justify-content: center;
        width: auto !important;

        img {
          max-height: 50px !important;
        }
      }

      .content {
        padding: 10px;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        padding-top: 0;
        padding-bottom: 0;

        .meta {
          font-size: 1em;
          line-height: 1em;
          color: rgba(0,0,0,.6);
        }

        .header {
          display: block !important;
          margin: 0 !important;
        }
      }
    }

    .selected-header-selected-item {
      display: flex;
      flex-direction: row;

      .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;

        .meta {
          margin-top: 5px;
          font-size: 1em;
          line-height: 1em;
          color: rgba(0,0,0,.6);
        }

        .header {
          display: block !important;
          margin: 0 !important;
        }
      }
    }
  }
}

.premium-other {
  .actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.StripeElement {
  padding: 0 !important;
}
.StripeElement.StripeElementCard {
  padding: 10px !important;
}

.promo-form-container .field {
  flex: 1;
  margin-right: 1em;
}


.payment-loading {
  font-size: 22px;
  font-weight: 200;
  color: #2185d0;
}

.payment-loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis-animation steps(1,end) 2s infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
}

@keyframes ellipsis-animation {
  0%  { clip-path: inset(0 100% 0 0); }
  25% { clip-path: inset(0 66.6% 0 0); }
  50% { clip-path: inset(0 33.3% 0 0); }
  75% { clip-path: inset(0 0 0 0); }
}

*:focus, * {
  outline: none !important;
}

.payment-failed {
  color: rgb(252,92,94);
  font-weight: 600;
  font-size: 18px;
}

.form-field-error, .form-field-warn {
  padding-left: 2.67142857em !important;
  color: #9f3a38;
  font-weight: bold;
}

.form-field-warn {
  color: #b58105;
}

.privacy {
  margin-top: 1em;
  font-size: .8em;
  font-style: italic;
  line-height: 1.3em;
  color: #484848;
}